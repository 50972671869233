import React, { useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const LoadingAnimation = () => {
  const particlesInit = useCallback(async engine => {
    
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'black',
      color: 'white',
      position: 'relative',
      overflow: 'hidden',
    }}>
      

      <AnimatePresence>
        <motion.img
          key="logo"
          src="/streamtify.svg"
          alt="Streamtify Logo"
          style={{ 
            width: 150, 
            height: 150,
            position: 'relative',
            zIndex: 1
          }}
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -100, opacity: 0 }}
          transition={{ duration: 0.5, repeat: Infinity, repeatType: "loop", repeatDelay: 4 }}
        />

        <motion.h1
          key="title"
          style={{ 
            fontSize: '2rem', 
            marginTop: '1rem',
            letterSpacing: '0.3em',
            position: 'relative',
            zIndex: 1
          }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2, repeat: Infinity, repeatType: "loop", repeatDelay: 4 }}
        >
          Streamtify
        </motion.h1>

        <motion.p
          key="subtitle"
          style={{ 
            fontSize: '1rem', 
            marginTop: '0.1rem',
            position: 'relative',
            zIndex: 1
          }}
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.4, repeat: Infinity, repeatType: "loop", repeatDelay: 4 }}
        >
          El streaming nacional en un solo lugar
        </motion.p>
      </AnimatePresence>
    </div>
  );
};

export default LoadingAnimation;