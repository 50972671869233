import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Card, CardContent, TextField, Typography, Box, useMediaQuery, useTheme, Modal, Accordion, AccordionSummary, AccordionDetails, CircularProgress, IconButton, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import LoadingAnimation from './LoadingAnimation';
import * as amplitude from '@amplitude/analytics-browser';
import ReactMarkdown from 'react-markdown';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import TreeItem from '@mui/lab/TreeItem';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import PictureInPictureView from './PictureInPictureView';

// Initialize Amplitude
amplitude.init('ac19e2fad37475846d67cbbd436e5ded');

const StreamingApp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const SERVER_URL = 'https://streamtify.onrender.com';
  //const SERVER_URL = 'http://localhost:3001';
  const [liveChannels, setLiveChannels] = useState([]);
  const [offlineChannels, setOfflineChannels] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isNarrow, setIsNarrow] = useState(false);
  const [expandedChannelId, setExpandedChannelId] = useState(null);
  const containerRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [sortBy, setSortBy] = useState('viewers');
  const [sortOrder, setSortOrder] = useState('desc');
  const [pipChannel, setPipChannel] = useState(null);
  const [isPipOpen, setIsPipOpen] = useState(false);
  const [pipInitialPosition, setPipInitialPosition] = useState({ x: 0, y: 0 });

  const handleSuggestionCardClick = () => {
    setIsModalOpen(true);
     // Track suggestion card click event
    amplitude.track('Suggestion Card Clicked', {
      programTitle: 'form'
    });

  };

  const handleCafecitoClick = () => {
    // Track Cafecito button click event
    amplitude.track('Cafecito Button Clicked', {
      buttonLocation: 'bottom-right'
    });
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const searchFieldWidth = document.getElementById('searchField').offsetWidth;
        setIsNarrow(searchFieldWidth < containerWidth * 0.5); // Verificar si el ancho del buscador es menor al 50% del contenedor
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamar a la función al cargar

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchChannels = useCallback(async () => {
    try {
      const response = await fetch(`${SERVER_URL}/api/channels`);
      const data = await response.json();
      const timestamp = Date.now();
      
      // Process live channels
      const updatedLiveChannels = data.livesChannels.map(channel => ({
        ...channel,
        logoUrl: channel.logoUrl,
        thumb: `${channel.thumb}?t=${timestamp}`
      }));
      
      // Process offline channels
      const updatedOfflineChannels = data.channelsNotInLives.map(channel => ({
        ...channel,
        logoUrl: channel.logoUrl
      }));
      
      setLiveChannels(updatedLiveChannels);
      setOfflineChannels(updatedOfflineChannels);

      // Set the first live channel as current if there's no current channel
      if (updatedLiveChannels.length > 0 && !currentChannel) {
        setCurrentChannel(updatedLiveChannels[0]);
      }
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  }, [expandedChannelId, currentChannel]);

  useEffect(() => {
    fetchChannels();
    const intervalId = setInterval(fetchChannels, 4 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [fetchChannels]);

  const handleChannelClick = useCallback(async (channel) => {
    setCurrentChannel(prevChannel => {
      if (prevChannel && prevChannel.id === channel.id) {
        return prevChannel;
      }
      return channel;
    });

    // Track channel click event
    amplitude.track('Channel Clicked', {
      programTitle: channel.currentStreamTitle || 'Unknown',
      medium: channel.name
    });

    try {
      const response = await fetch(`${SERVER_URL}/api/channelTitle?youtubeId=${channel.youtubeId}`);
      const data = await response.json();
      setCurrentChannel(prevChannel => ({
        ...prevChannel,
        currentStreamTitle: data.title,
      }));
    } catch (error) {
      console.error('Error fetching video title:', error);
    }
  }, []);


  const filteredLiveChannels = liveChannels.filter(channel =>
    (channel.name && channel.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (channel.currentStreamTitle && channel.currentStreamTitle.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const sortedLiveChannels = filteredLiveChannels.sort((a, b) => {
    if (sortBy === 'viewers') {
      return sortOrder === 'asc' ? a.viewers - b.viewers : b.viewers - a.viewers;
    } else {
      return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    }
  });

  const filteredOfflineChannels = offlineChannels.filter(channel =>
    channel.name && channel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!currentChannel) {
    return <LoadingAnimation />;
  }

  const handlePipToggle = (channel, event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const initialPosition = {
      x: rect.left,
      y: rect.top,
    };
    setPipInitialPosition(initialPosition);
    setPipChannel(prevChannel => {
      if (prevChannel && prevChannel.id === channel.id) {
        setIsPipOpen(false);
        return null;
      } else {
        setIsPipOpen(true);
        return channel;
      }
    });
    
    // Track PiP toggle event
    amplitude.track('PiP Toggled', {
      channelName: channel.name,
      channelId: channel.id,
      action: pipChannel && pipChannel.id === channel.id ? 'closed' : 'opened'
    });
  };

  const handleClosePip = () => {
    setIsPipOpen(false);
    setTimeout(() => setPipChannel(null), 300); // Espera a que termine la animación antes de eliminar el canal
  };

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      overflow: 'hidden'
    }}>
      {/* Título y Subtítulo */}
      <Box sx={{ 
        padding: '8px', 
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '64px', // Mantenemos la altura fija
      }}>
        <Typography 
          variant="h4" 
          color="white" 
          sx={{ 
            animation: 'fadeIn 1s ease-in-out',
            lineHeight: 1,
            marginBottom: '4px',
            fontSize: { xs: '1.5rem', sm: '2rem' }, // Ajustamos el tamaño para dispositivos pequeños
          }}
        >
          Streamtify 🇦🇷
        </Typography>
        <Typography 
          variant="subtitle2" 
          color="white" 
          sx={{ 
            opacity: 0.8,
            fontSize: { xs: '0.7rem', sm: '0.8rem' }, // Ajustamos el tamaño para dispositivos pequeños
          }}
        >
          El streaming nacional en un solo lugar
        </Typography>
      </Box>

      {/* Contenedor principal */}
      <Box sx={{ 
        flex: 1, 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row', 
        overflow: 'hidden'
      }}>
        {/* Iframe - Fijo en su lugar */}
        <Box sx={{ 
          flex: isMobile ? 1 : '0 0 75%', // Cambiar a 1 para ocupar todo el espacio disponible
          position: 'relative',
          overflow: 'hidden'
        }}>
          {currentChannel && (
            <iframe
              key={currentChannel.id} // Add a key to prevent unnecessary rerendering
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: isMobile ? '100%' : '100%', // Cambiar altura a 75% en móvil
                border: 'none'
              }}
              src={`https://www.youtube.com/embed/${currentChannel.youtubeId}?autoplay=1`}
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          )}
        </Box>

        {/* Lista de canales */}
        <Box sx={{ 
          flex: isMobile ? '0 0 67%' : 1, // Cambiar a 25% en móvil
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}>
          {/* Barra de búsqueda y filtros */}
          <Box sx={{ padding: '8px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <TextField
              id="searchField"
              label="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              size="small"
              sx={{ flex: 1, marginRight: 1 }} // Ocupa el espacio disponible y añade margen a la derecha
            />
            {/* Selector de ordenamiento */}
            <FormControl size="small" sx={{ marginRight: 1 }}>
              <InputLabel>Ordenar por</InputLabel>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                label="Ordenar por"
              >
                <MenuItem value="viewers">Viewers</MenuItem>
                <MenuItem value="name">Nombre</MenuItem>
              </Select>
            </FormControl>
            {/* Botón de ordenamiento */}
            <IconButton 
              onClick={() => {
                setSortOrder(prevOrder => prevOrder === 'asc' ? 'desc' : 'asc'); // Cambiar entre asc y desc
              }} 
              size="small"
            >
              {sortOrder === 'asc' ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
            </IconButton>
          </Box>

          {/* Lista de canales scrollable */}
          <Box sx={{ 
            flex: 1, // Ocupa el espacio restante
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
            paddingBottom: '56px',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}>
            {/* Live Channels */}
           
            {sortedLiveChannels.map((channel) => (
              <Box key={channel.id} sx={{ marginBottom: '8px', flexShrink: 0 }}>
                <ChannelCard 
                  channel={channel} 
                  onClick={handleChannelClick} 
                  onPipToggle={handlePipToggle}
                  isMobile={isMobile}
                  isActive={currentChannel && currentChannel.id === channel.id}
                  isPip={pipChannel && pipChannel.id === channel.id}
                  SERVER_URL={SERVER_URL}
                  isExpanded={expandedChannelId === channel.id}
                  onExpand={(channelId) => setExpandedChannelId(channelId)}
                />
              </Box>
            ))}
            {/* Offline Channels */}
        <Typography variant="h6" sx={{ marginTop: '16px', marginBottom: '8px' }}>Sin transmisión</Typography>
        <Grid container spacing={2}>
          {filteredOfflineChannels.map((channel, index) => (
            <Grid item xs={6} key={channel.id}>
              <OfflineChannelCard 
                channel={channel}
                isMobile={isMobile}
              />
            </Grid>
          ))}
        </Grid>
            {/* Agregamos la nueva card de sugerencia al final */}
            <Box sx={{ flexShrink: 0, marginTop: '16px' }}>
              <SuggestionCard isMobile={isMobile} onClick={handleSuggestionCardClick} />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Botón de Cafecito */}
      <Box sx={{ 
        position: 'fixed', 
        bottom: '8px', 
        right: '8px', 
        zIndex: 1000 
      }}>
        <a 
          href='https://cafecito.app/franisla' 
          rel='noopener noreferrer'
          target='_blank' 
          onClick={handleCafecitoClick}
        >
          <img 
            srcSet='https://cdn.cafecito.app/imgs/buttons/button_3.png 1x, https://cdn.cafecito.app/imgs/buttons/button_3_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_3_3.75x.png 3.75x' 
            src='https://cdn.cafecito.app/imgs/buttons/button_3.png' 
            alt='Invitame un café en cafecito.app' 
            style={{ height: '40px' }}
          />
        </a>
      </Box>

      {/* Modal para el iframe */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="suggestion-modal"
        aria-describedby="suggestion-form"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 2,
          }}>
            <Button 
              onClick={handleCloseModal}
              sx={{ 
                color: 'text.secondary',
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box sx={{
            flexGrow: 1,
            padding: 2,
            paddingTop: 0,
          }}>
            <iframe 
              className="airtable-embed" 
              src="https://airtable.com/embed/appJR9VsA2Vg1rWi7/pagszv1q1wu912edx/form" 
              frameBorder="0" 
              width="100%" 
              height="80%" 
              style={{ background: 'transparent', border: '1px solid #ccc' }}
            />
            
          </Box>
          
        </Box>
        
      </Modal>
            {/* Picture in Picture View */}
            {pipChannel && (
        <PictureInPictureView
        channel={pipChannel}
        onClose={handleClosePip}
        isMobile={isMobile}
        isOpen={isPipOpen}
        initialPosition={pipInitialPosition}
        />
      )}
      <SpeedInsights />
      <Analytics />
    </Box>
  );
};


const ChannelCard = ({ channel, onClick, onPipToggle, isMobile, isActive, SERVER_URL, isExpanded, onExpand }) => {
  //const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transcription, setTranscription] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [showHistory, setShowHistory] = useState(false); // Nuevo estado para manejar la vista del historial
  const [previousExpandedId, setPreviousExpandedId] = useState(null); // Nuevo estado para almacenar el ID expandido anterior

  const fetchTranscription = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(`${SERVER_URL}/api/transcription?youtubeId=${channel.youtubeId}`);
      if (!response.ok) {
        if (response.status === 404) {
          setTranscription("Lo siento, no encontré transcripción para esta emisión");
        } else {
          throw new Error("Error al obtener la transcripción");
        }
      } else {
        const data = await response.json();
        setTranscription(`${data.summarization}\n`);
        // Parse the timestamp correctly
        const [date, time] = data.timestamp.split(' ');
        const [day, month, year] = date.split('/');
        const [hour, minute] = time.split(':');
        const parsedTimestamp = new Date(year, month - 1, day, hour, minute);
        setTimestamp(parsedTimestamp);
      }
    } catch (error) {
      console.error('Error fetching transcription:', error);
      setTranscription("Lo siento, hubo un problema al obtener la transcripción.");
    } finally {
      setLoading(false);
    }
  }, [channel.youtubeId, SERVER_URL]);

  const handleAccordionChange = (event, isExpanded) => {
    event.stopPropagation(); // Prevent card click when expanding accordion
    if (isExpanded) {
      if (showHistory) { // Verificar si el historial está abierto
        setShowHistory(false); // Cerrar el historial
      }
      setPreviousExpandedId(channel.id); // Almacenar el ID expandido
      onExpand(channel.id);
      fetchTranscription();
      // Track accordion expansion
      amplitude.track('Accordion Expanded', {
        channelName: channel.name,
        channelId: channel.id
      });
    } else {
      onExpand(null);
      // Track accordion contraction
      amplitude.track('Accordion Contracted', {
        channelName: channel.name,
        channelId: channel.id
      });
    }
  };

  const handleCardClick = (event) => {
    // Solo llamar onClick si el clic no ocurrió en el Accordion
    if (!event.target.closest('.MuiAccordion-root')) {
      if (previousExpandedId && previousExpandedId !== channel.id) {
        // No actualizar el iframe si se hace clic en otra tarjeta
        return;
      }
      onClick(channel);
    }
  };

  const handlePipClick = (event) => {
    event.stopPropagation(); // Prevent the card click event
    onPipToggle(channel, event); // Pass both channel and event
  };

  const handleSearchTranscriptions = async (event) => {
    event.stopPropagation(); // Evitar que el clic cierre el acordeón
    setLoading(true); 
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toISOString().split('T')[0]; // Set startDate to yesterday
    const endDate = today.toISOString().split('T')[0]; // Keep endDate as today
    
    try {
      const response = await fetch(`${SERVER_URL}/api/searchTranscriptions?name=${encodeURIComponent(channel.name)}&startDate=${startDate}`);
      if (!response.ok) {
        throw new Error("Error al buscar transcripciones");
      }
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error searching transcriptions:', error);
      setSearchResults("Lo siento, hubo un problema al buscar las transcripciones.");
    } finally {
      setLoading(false);
    }
  };

  const handleShowHistory = (event) => {
    event.stopPropagation(); // Evitar que el clic cierre el acordeón
    handleSearchTranscriptions(event); // Llamar al buscador de transcripciones
    setShowHistory(true); // Cambiar a la vista de historial
  };

  const handleBack = (event) => {
    event.stopPropagation(); // Evitar que el clic se propague a la tarjeta
    setShowHistory(false); // Volver a la vista principal
    // No cambiar el canal ni activar la tarjeta
    if (previousExpandedId !== channel.id) {
        return; // Si el canal actual no es el que se está expandiendo, no hacer nada
    }
    onExpand(previousExpandedId); // Restaurar el estado de expansión anterior
  };

  const renderSearchResults = () => {
    if (!Array.isArray(searchResults)) {
      return <Typography color="error">{searchResults}</Typography>;
    }

    // Group results by currentStreamTitle
    const groupedResults = searchResults.reduce((acc, result) => {
      const title = result.currentStreamTitle || 'Sin título disponible';
      if (!acc[title]) {
        acc[title] = [];
      }
      acc[title].push(result);
      return acc;
    }, {});

    return (
      <>
        {Object.entries(groupedResults).map(([title, results]) => (
          <Accordion key={title}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {results.map((result, index) => {
                const timestamp = new Date(result.timestamp); // Convert timestamp to Date object
                return (
                  <Box key={index} sx={{ mb: 1, p: 1, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                    <Typography variant="caption" sx={{ display: 'block', mb: 1, color: 'text.secondary' }}>
                      Actualizado: {timestamp.toLocaleDateString('es-ES')} {timestamp.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                    </Typography>
                    <ReactMarkdown>{result.summarization}</ReactMarkdown>
                  </Box>
                );
              })}
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  };

  return (
    <Card 
      onClick={handleCardClick}
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        },
        width: '100%',
        overflow: 'hidden',
        backgroundColor: isActive ? 'rgba(25, 118, 210, 0.1)' : 'inherit',
      }}
    >
      <Box>
        {showHistory ? ( // Condicional para mostrar el historial
          <Box>
            <Box onClick={(event) => event.stopPropagation()}> {/* Contenedor que detiene la propagación */}
              <Button onClick={handleBack} startIcon={<ArrowBack />}>Volver</Button> {/* Botón para volver */}
            </Box>
            {renderSearchResults()} {/* Agregar el acordeón de resultados aquí */}
          </Box>
        ) : (
          <>
            {/* Card content remains the same */}
            <Box sx={{
              display: 'flex',
              height: isMobile ? '100px' : '120px',
              backgroundImage: `url(${channel.thumb})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              position: 'relative', // Añadido para posicionar el botón de PiP
            }}>
              
              {/* Logo and viewer count */}
              <Box sx={{ 
                width: isMobile ? '30%' : '25%',
                height: '100%',
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                padding: '8px',
              }}>
                <img 
                  src={channel.logoUrl} 
                  alt={`${channel.name} logo`} 
                  style={{ 
                    width: '70%', 
                    height: 'auto', 
                    objectFit: 'contain',
                    borderRadius: '50%', 
                    maxHeight: '70%',
                  }} 
                />
                <Typography variant="caption" sx={{ color: 'white', fontSize: '0.7rem', marginTop: '4px' }}>
                  <PeopleIcon sx={{ fontSize: '0.9rem', verticalAlign: 'middle', marginRight: '2px' }} />
                  {channel.viewers}
                </Typography>
                
              </Box>
              
              {/* Channel name and stream title */}
              <Box sx={{ 
                flex: 1, 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                padding: '8px', 
                backgroundColor: 'rgba(0, 0, 0, 0.7)', 
                overflow: 'hidden' 
              }}>
                
                <Typography 
                  variant="subtitle1" 
                  color="white" 
                  fontWeight="bold" 
                  sx={{ 
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    fontSize: '0.9rem',
                    marginBottom: '4px',
                  }}
                >
                  {channel.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="white" 
                  sx={{ 
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                    fontSize: '0.75rem',
                    opacity: 0.8,
                  }}
                >
                  {channel.currentStreamTitle}
                </Typography>
                
              </Box>
              {/* Botón de PiP */}
              <IconButton
                onClick={handlePipClick}
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  color: 'white',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(0,0,0,0.7)',
                  },
                }}
              >
                <PictureInPictureIcon />
              </IconButton>
            </Box>
            <Accordion 
              expanded={isExpanded} 
              onChange={handleAccordionChange}  
              sx={{ 
                '&:before': {
                  display: 'none',
                },
                boxShadow: 'none',
              }}
            >
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  minHeight: '48px',
                  '& .MuiAccordionSummary-content': {
                    margin: '12px 0',
                  },
                }}
              >
                <Typography>¿De qué hablan? (beta)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {timestamp && (
                      <Typography variant="caption" sx={{ display: 'block', marginBottom: '8px', color: 'text.secondary' }}>
                        Actualizado: {timestamp.toLocaleDateString('es-ES')} {timestamp.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                      </Typography>
                    )}
                    <ReactMarkdown>{transcription}</ReactMarkdown>
                    <Button 
                      variant="outlined" 
                      color="primary" 
                      onClick={handleShowHistory} // Cambiar a la vista de historial
                      sx={{ mt: 2, mb: 2 }}
                    >
                      {loading ? <CircularProgress size={24} /> : 'Ver historial'}
                    </Button>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
        
      </Box>
    </Card>
    
  );
};



// Nueva componente para la card de sugerencia
const SuggestionCard = ({ isMobile, onClick }) => {
  return (
    <Card 
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        },
        height: isMobile ? '80px' : '100px',
        width: '100%',
        backgroundColor: 'rgba(25, 118, 210, 0.7)', // #1976D2 con 70% de opacidad
        flexDirection: 'row',
      }} 
      onClick={onClick}
    >
      <Box sx={{ 
        flex: 1, 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        padding: '16px', 
        overflow: 'hidden' 
      }}>
        <Typography 
          variant="h6" 
          color="white" 
          fontWeight="bold" 
          sx={{ 
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            fontSize: '0.9rem',
            textAlign: 'center',
          }}
        >
          ¿No encontraste tu canal favorito? Proponelo presionando aquí.
        </Typography>
      </Box>
    </Card>
  );
};
// Añadir estilos globales para la animación
const styles = `
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
`;


const OfflineChannelCard = ({ channel, isMobile }) => {
  const handleChannelClick = () => {
    window.open(`https://www.youtube.com/channel/${channel.id}`, '_blank');
  };

  return (
    <Card 
      onClick={handleChannelClick}
      sx={{ 
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        },
        height: isMobile ? '60px' : '80px',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ 
        width: isMobile ? '40px' : '60px',
        height: isMobile ? '40px' : '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        marginLeft: '8px',
      }}>
        <Box
          component="img"
          src={channel.logoUrl} 
          alt={`${channel.name} logo`} 
          sx={{ 
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '50%',
          }} 
        />
      </Box>
      <Box sx={{ 
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '4px 8px',
        overflow: 'hidden',
      }}>
        <Typography 
          variant="subtitle2" 
          fontWeight="bold" 
          sx={{ 
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            fontSize: '0.8rem',
          }}
        >
          {channel.name}
        </Typography>
      </Box>
    </Card>
  );
};

// Inyectar estilos en el documento
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default StreamingApp;