import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const PictureInPictureView = ({ channel, onClose, isMobile }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isMuted, setIsMuted] = useState(true); // Estado para manejar el mute
  const pipRef = useRef(null);

  const pipWidth = isMobile ? 240 : 320;
  const pipHeight = isMobile ? 150 : 200;

  useEffect(() => {
    const defaultPosition = isMobile
      ? { x: window.innerWidth - pipWidth - 10, y: window.innerHeight - pipHeight - 58 }
      : { x: window.innerWidth * 0.75 - pipWidth, y: window.innerHeight - pipHeight - 10 };
    setPosition(defaultPosition);
  }, [isMobile, pipWidth, pipHeight]);

  const handleDragStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    const rect = pipRef.current.getBoundingClientRect();
    const clientX = e.clientX || (e.touches && e.touches[0].clientX);
    const clientY = e.clientY || (e.touches && e.touches[0].clientY);
    setDragOffset({
      x: clientX - rect.left,
      y: clientY - rect.top,
    });
  };

  const handleDragMove = (e) => {
    if (isDragging) {
      const clientX = e.clientX || (e.touches && e.touches[0].clientX);
      const clientY = e.clientY || (e.touches && e.touches[0].clientY);
      const newX = Math.max(0, Math.min(clientX - dragOffset.x, window.innerWidth - pipWidth));
      const newY = Math.max(0, Math.min(clientY - dragOffset.y, window.innerHeight - pipHeight));
      setPosition({ x: newX, y: newY });
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDragMove);
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('touchmove', handleDragMove);
      window.addEventListener('touchend', handleDragEnd);
    }
    return () => {
      window.removeEventListener('mousemove', handleDragMove);
      window.removeEventListener('mouseup', handleDragEnd);
      window.removeEventListener('touchmove', handleDragMove);
      window.removeEventListener('touchend', handleDragEnd);
    };
  }, [isDragging]);

  return (
    <Box
      ref={pipRef}
      sx={{
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: `${pipWidth}px`,
        height: `${pipHeight}px`,
        backgroundColor: 'black',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        zIndex: 1000,
      }}
    >
      <iframe
        src={`https://www.youtube.com/embed/${channel.youtubeId}?autoplay=1&mute=${isMuted ? 1 : 0}`}
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          padding: '4px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton
          onMouseDown={handleDragStart}
          onTouchStart={handleDragStart}
          sx={{
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.7)',
            },
          }}
        >
          <OpenWithIcon fontSize="small" />
        </IconButton>
        {isMobile && (
          <IconButton
            onClick={toggleMute}
            sx={{
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': {
                backgroundColor: 'rgba(0,0,0,0.7)',
              },
            }}
          >
            {isMuted ? <VolumeOffIcon fontSize="small" /> : <VolumeUpIcon fontSize="small" />}
          </IconButton>
        )}
        <IconButton
          onClick={onClose}
          sx={{
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.5)',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.7)',
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PictureInPictureView;
